<template>
  <div class="homebg">
    <div class="info">
      <div class="first-line">
        <span>{{ $t('home.jrxzyh') }}：</span>
        <em>{{ todayinfo.t_user }}</em>
        <span>{{ $t('home.zyh') }}：</span>
        <em>{{ todayinfo.z_user }}</em>
        <span>{{ $t('home.yhzye') }}：</span>
        <em>{{ todayinfo.z_btc }}（BTC）≈ {{ todayinfo.z_balance }}（USD）</em>
      </div>
      <div class="show-data">
        <div class="main">
          <div class="left" style="background:#2389F2">
            {{ $t('home.jrdd') }}
          </div>
          <span class="right">{{ todayinfo.t_orders }}</span>
        </div>
        <div class="main">
          <div class="left" style="background:#FFC913">
            {{ $t('home.khyk') }}
          </div>
          <span class="right">{{ todayinfo.t_profit }}</span>
        </div>
        <div class="main">
          <div class="left" style="background:#FF6213">
            {{ $t('home.jrls') }}
          </div>
          <span class="right">{{ todayinfo.t_total }}</span>
        </div>
        <div class="main">
          <div class="left" style="background:#3C8ADC">
            {{ $t('home.jrcz') }}
          </div>
          <span class="right">{{ todayinfo.t_recharge }}</span>
        </div>
        <div class="main">
          <div class="left" style="background:#6F64F9">
            {{ $t('home.jrtx') }}
          </div>
          <span class="right">{{ todayinfo.t_withdraw }}</span>
        </div>
        <div class="main">
          <div class="left" style="background:#00D38E">
            {{ $t('home.dtsxf') }}
          </div>
          <span class="right">{{ todayinfo.t_fee }}</span>
        </div>
      </div>
    </div>
    <div class="app-container">
      <el-table
        v-loading="loading"
        :data="infolist"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="orderno"
          :label="$t('home.bianma')"
        ></el-table-column>
        <el-table-column
          prop="username"
          :label="$t('home.yhm')"
        ></el-table-column>
        <el-table-column
          prop="open_price"
          :label="$t('home.gmdj')"
        ></el-table-column>
       <!-- <el-table-column
          prop="times"
          :label="$t('home.dqsj')"
        ></el-table-column> -->
        <el-table-column
          prop="ratio"
          :label="$t('home.lilv')"
        ></el-table-column>
        <el-table-column
          prop="total_price"
          :label="$t('home.gmzj')"
        ></el-table-column>
        <el-table-column
          prop="open_balance"
          :label="$t('home.jshye')"
        ></el-table-column>
        <el-table-column
          prop="end_price"
          :label="$t('home.jssdj')"
        ></el-table-column>
        <el-table-column prop="profit" :label="$t('home.jsscsfy')">
          <template slot-scope="{ row }">
            <span
              style="color:#f00;font-weight:600"
              v-if="parseInt(row.profit) > 0"
              >{{ row.profit }}</span
            >
            <span style="color:#008000;font-weight:600" v-else>{{
              row.profit
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="end_balance"
          :label="$t('home.jshye')"
        ></el-table-column>
        <el-table-column prop="status" :label="$t('home.zhuangtai')">
          <template slot-scope="{ row }">
            <span v-if="row.status === '0'">{{ $t('home.zzjx') }}</span>
            <span v-else>{{ $t('home.ywc') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" :label="$t('home.leixing')">
          <template slot-scope="{ row }">
            <span v-if="row.type === '1'">{{ $t('home.jieduo') }}</span>
            <span v-else>{{ $t('home.jiekong') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" :label="$t('home.cjsj')"
          ><template slot-scope="{ row }">
            {{ row.createtime | dateformat }}
          </template>
        </el-table-column>
        <el-table-column
          prop="product_name"
          :label="$t('bizhong')"
        ></el-table-column>
      <el-table-column prop="risk" :label="$t('home.zhuangtai')">
        <template slot-scope="{ row }">
          <span style="color:#00aa00;font-weight:600" v-if="row.risk === '1'">控盈</span>
          <span style="color:#ff0000;font-weight:600" v-else-if="row.risk === '2'">控输</span>
          <span style="color:#FFC913;font-weight:600" v-else>不控</span>
        </template>
         </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryinfo: {
        query: '',
        page: 1
      },
      infolist: [],
      total: 0,
      loading: false,
      todayinfo: {}
    }
  },
  created() {
    this.getlist()
    this.gettodayinfo()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/orders/orderlist', {
        params: this.queryinfo
      })
      this.infolist = data.data.list
      this.total = +data.data.count
      this.order_count = data.data.o_count
      this.total_money = data.data.total
      this.profit = data.data.profit
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    async gettodayinfo() {
      const { data } = await this.$http.get('/admin/orders/todayinfo')
      if (data) {
        if (data.code === 200) {
          this.todayinfo = data.data
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.homebg {
  background: #eee;
  box-sizing: border-box;
  padding: 20px;
  margin: -20px;
  .info {
    margin-left: 20px;
    .first-line {
      margin: 20px 0 20px;
      font-size: 18px;
      span {
        font-size: 20px;
      }
      em {
        font-style: normal;
        margin-right: 20px;
      }
    }
    .show-data {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .main {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 60px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #fff;
        margin-right: 20px;
        text-align: center;
        border-radius: 10px;
        .left {
          color: #fff;
          height: 100%;
          line-height: 60px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: 0 10px;
        }
        .right {
          width: 100px;
          color: #000;
        }
      }
    }
  }
  .app-container {
    padding: 20px;
  }
}
</style>
